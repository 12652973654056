<template>
  <div>
  <v-dialog
    v-model="activo"
    width="350"
    :persistent="activo"
    scrollable
  >
    <v-card>
      <v-card-title class="">
        <div style="font-size: 20px">{{ accion.length==0 ? 'Generar Liquidación' : 'Elegir periodo' }}</div>
        <v-spacer></v-spacer>
        <v-btn right icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-3">
        Período
        <PeriodoPickerVue v-model="periodo" clearable/>
      </v-card-text>
      <!-- BOTONES -->
      <v-card-actions class="d-flex justify-center pb-4">
        <v-btn
          class="mr-2"
          color="secondary"
          @click="activo = false"
        >cancelar</v-btn>
        <BtnConfirmarVue
          v-if="accion!='descargar'"
          :disabled="!hayPeriodo"
          :icono="accion == '' ? 'fas fa-download' : 'fas fa-trash'" 
          :texto="accion == '' ? '¿Desea generar una nueva liquidación?' : `Está seguro de eliminar el período de liquidación ${this.periodo}. ¿Confirma esta accion?`"
          :color="'info'"
          :loading="load"
          :nombre="accion=='' ? 'GENERAR' : 'ELIMINAR'"
          @action="determinarAccion()"
        />
        <v-btn @click="determinarAccion()" v-if="accion=='descargar'" color="info">
          <v-icon>
            fas fa-download
          </v-icon>
           DESCARGAR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import PeriodoPickerVue from '../util/PeriodoPicker.vue';
import BtnConfirmarVue from '../util/BtnConfirmar.vue';
import download from 'downloadjs'

export default {
  name: 'ModalNewElimDesc',
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default: false
    }
  },
  computed:{
    activo:{
      get(){
        return this.datos.dialog;
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    periodoSelect:{
      get(){
        return this.datos.periodoSelect;
      },
    },
    accion:{
      get(){
        return this.datos.accion;
      },
    },
    ultPeriodo: {
      get(){
        return this.datos.ultPeriodo;
      },
    }
  },
  data(){
    return{
      hayPeriodo: false,
      periodo: '',
      load: false,
    }
  },
  created(){

  },
  methods:{
    async determinarAccion(){
      if(this.accion == ''){
        this.generarPeriodo();
        return
      }
      if(this.accion == 'descargar'){
        this.descargar();
        return
      }
      if(this.accion == 'eliminar'){
        this.eliminarPeriodo();
        return
      }
    },
    async generarPeriodo(){
      this.$store.state.loading = true;
      this.load = true;
      const res = await this.$store.dispatch('liquidaciones/nuevaLiquidaciones', { periodo: '01/'+this.periodo })
      this.$store.state.loading = false;
      this.load = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', })
      }
      this.$swal.fire({
        icon: 'success',
        title: 'Se genero con éxito la Liquidación',
        text: '',
        timer: 3000
      });
      this.$emit('buscarPeriodo', this.periodo);
      this.activo = false;
    },
    async descargar(){
      if(this.periodo && (new Date('01/'+this.periodo).getTime()) <= (new Date('01/'+this.ultPeriodo).getTime())){
        if(!this.periodo){
          return this.$store.dispatch('show_snackbar', { text: 'Debe especificar el campo periodo', color: 'info', })
        }
        this.$store.state.loading = true;
        const res = await this.$store.dispatch('liquidaciones/obtenerExcel', '01/'+this.periodo)
        
        if(res.resultado == 0){
          this.$store.state.loading = false;
          return this.$store.dispatch('show_snackbar', { text: res.message,  color: 'error',  })
        }

        this.$store.state.loading = false
        await download(res.base_64, `periodo_${this.periodo}.xlsx`, 'application/vnd.ms-excel')

        this.activo = false;
      }else return this.$store.dispatch('show_snackbar', { text: 'El periodo no debe ser superior al actual',  color: 'warning',  })
    },
    async eliminarPeriodo(){
      //if(this.periodo && (new Date('01/'+this.periodo).getTime()) == (new Date('01/'+this.ultPeriodo).getTime())){
        if(!this.periodo){
          return this.$store.dispatch('show_snackbar', { text: 'Debe especificar el campo periodo', color: 'info', })
        }
        this.$store.state.loading = true;
        const res = await this.$store.dispatch('liquidaciones/borrarPeriodo', { periodo: '01/'+this.periodo })
        this.$store.state.loading = false;

        if(res.resultado == 0){
          return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error',})
        }
        console.log("res: ", res);
        this.$swal.fire({
          icon: 'success',
          text: 'El período de liquidación se eliminó correctamente',
          title: 'Período Eliminado.',
          timer: 3000
        });
        this.$emit('buscarPeriodo', this.periodo);
        this.activo = false;
      //}else return this.$store.dispatch('show_snackbar', { text: 'El periodo debe ser el mes actual.',  color: 'warning',  })   
    }
  }, 
  components:{
    PeriodoPickerVue, BtnConfirmarVue
  },
  watch:{
    activo: function(val){
      if(!val){
        this.periodo = null;
      }else this.periodo = this.periodoSelect
    },
    periodo: function(val){
      if(val){
        this.hayPeriodo = true;
      }else this.hayPeriodo = false;
    },
  }
}
</script>

<style>

</style>