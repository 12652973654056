<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12" class="pb-2 px-1">
        <!-- FILTROS -->
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <div>
              <v-btn
                style="position: absolute; top: -15px; right: 165px; z-index: 1"
                color="error"
                title="Eliminar Liquidación"
                small
                fab
                v-if="buscado"
                @click="eliminarLiq()"
              >
                <v-icon>fas fa-trash</v-icon>
              </v-btn>
              <v-btn 
                v-if="buscado"
                style="position: absolute; top: -15px; right: 110px; z-index: 1"
                color="info"
                title="Descargar Liquidación"
                small
                fab
                @click="descargar()"
              >
                <v-icon>fas fa-file-download</v-icon>
              </v-btn>
              <v-btn
                style="position: absolute; top: -15px; right: 55px; z-index: 1"
                color="success"
                title="Nueva Liquidación"
                small
                fab
                @click="abrirNuevoPeriodo()"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
            </div>
            <v-expansion-panel-header class="py-0 px-4 text-h5">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtro
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar()">
                <v-row class="pt-2">
                  <v-col cols="12" sm="6" md="2" class="pt-1">
                    Período
                    <PeriodoPickerVue
                    clearable
                      v-model="filtroLiquidaciones.periodo"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="2" class="pt-1">
                    Concepto
                    <v-autocomplete
                      v-model="filtroLiquidaciones.concepto_id"
                      item-text="concepto_nombre"
                      item-value="concepto_id"
                      tabindex="1"
                      :items="conceptos"
                      hide-details
                      outlined
                      dense
                      clearable 
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="2" class="pt-1">
                    N° Reserva
                    <v-text-field
                      v-model="filtroLiquidaciones.reserva_id"
                      type="number"
                      tabindex="1"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="2" class="pt-1">
                    Nro. Contrato
                    <v-text-field
                      v-model="filtroLiquidaciones.nro_contrato"
                      tabindex="1"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="6" md="2" class="pt-1">
                    Origen
                    <v-autocomplete
                      v-model="filtroLiquidaciones.canal_id"
                      item-text="canal_nombre"
                      item-value="canal_id"
                      tabindex="1"
                      :items="origenes"
                      hide-details
                      outlined
                      dense
                      clearable 
                    ></v-autocomplete> 
                  </v-col>-->
                  <v-col cols="6" sm="6" md="4" class="d-flex justify-end">
                    <BtnFiltroVue
                      :loading="load"
                      @action="buscar()"
                      clase="mt-0"
                      @clear="limpiarFiltros()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- DETALLES Y RESUMEN -->
      <v-col cols="12" class="elevation-2 px-1">
        <v-tabs v-model="tab">
           
          <span 
            style="margin-right:5px; border-radius: 20px 20px 0px 0px;" 
            :style="$vuetify.theme.dark && tab==0? 'border: 1px solid white; background-color: #928d8d; color: white;' : 
                                                    (!$vuetify.theme.dark && tab==0? 'border: 1px solid black; background-color: var(--v-disabled-base);' : 
                                                                                      ($vuetify.theme.dark && tab!=0? 'border: 1px solid white; background-color: var(--v-disabled-base); color: white;' : 
                                                                                                                      'border: 1px solid black;') )" 
            class="d-flex"
          >
            <v-tab>
              DETALLE
            </v-tab>
          </span>
          <span 
            style="margin-right:5px; border-radius: 20px 20px 0px 0px;" 
            :style="$vuetify.theme.dark && tab==1? 'border: 1px solid white; background-color: #928d8d; color: white;' : 
                                                    (!$vuetify.theme.dark && tab==1? 'border: 1px solid black; background-color: var(--v-disabled-base);' : 
                                                                                      ($vuetify.theme.dark && tab!=1? 'border: 1px solid white; background-color: var(--v-disabled-base); color: white;' : 
                                                                                                                      'border: 1px solid black;') )" 
            class="d-flex"
          >
            <v-tab color="tableVta">
              RESUMEN
            </v-tab>
          </span>
          <v-spacer></v-spacer>
          <v-col cols="6" sm="3" md="2">
            <SearchDataTableVue
              v-if="tab==0"
              v-model="search"
            />
            <SearchDataTableVue
              v-if="tab==1"
              v-model="search2"
            />
          </v-col>
        </v-tabs>
        <v-tabs-items v-model="tab" class="">
          <v-tab-item>
            <v-data-table
              :style="tab==0 ? '' : ''"
              class="cebra mt-2 elevation-1"
              :headers="header_detalles"
              :items="liquidacionesDetalles"
              :loading="load"
              :search="search"
              :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
              dense
            >
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para los filtros seleccionados
                </v-alert>
              </template>
              <template v-slot:[`item.acciones`]="{item}">
                <v-tooltip top>
                  <template  v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="orange"
                      small
                      @click="verEditarDet(item)"
                    >
                      fas fa-edit
                    </v-icon>
                  </template>
                  <span> Editar </span>
                </v-tooltip>
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="px-2"
                      @click="eliminarDetalle(item)"
                      v-on="on"
                      color="error"
                      small
                    >
                      fas fa-times
                    </v-icon>
                  </template>
                  <span> Eliminar </span>
                </v-tooltip>
                <v-tooltip top >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="pr-2"
                      @click="verReserva(item)"
                      v-on="on"
                      :color="$vuetify.theme.dark ? '#E0E0E0' : 'secondary'"
                      small
                    >
                      fas fa-car
                    </v-icon>
                  </template>
                  <span> Ver reserva </span>
                </v-tooltip>
                <v-tooltip bottom class="">
                  <template  v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="cyan"
                      title="Comentario"
                      small
                    >
                      fas fa-comment-dots
                    </v-icon>
                  </template>
                  <span> {{ item.comentario }} </span>
                </v-tooltip>
              </template>
              <!-- Fecha Desde -->
              <template v-slot:[`item.fecha_desde`]="{item}">
                {{ moment(item.fecha_desde).format('DD/MM/YY') }}
              </template>
              <!-- Fecha Hasta -->
              <template v-slot:[`item.fecha_hasta`]="{item}">
                {{ moment(item.fecha_hasta).format('DD/MM/YY') }}
              </template>              
              <template
                v-for="header in header_detalles.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
            </v-data-table> 
          </v-tab-item>
          <v-tab-item>
            <v-data-table
              class="cebra elevation-2 mt-2"
              :headers="header_resumen"
              :items="liquidacionesResumen"
              :loading="load2"
              :search="search2"
              :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
              dense
            >
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para los filtros seleccionados
                </v-alert>
              </template>
              <template
                v-for="header in header_resumen.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
            </v-data-table> 
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <!-- COMPONENTES  :datos="datos"-->
    <ModalNewElimDescVue
      v-model="objModalNewElimDesc.dialog"
      :datos="objModalNewElimDesc"
      @buscarPeriodo="buscarPeriodo"
    />
    <ModalVerEditarLiqVue
      v-model="objEditarLiq.dialog"
      :datos="objEditarLiq"
      @actualizarDetalleLiq="actualizarDetalleLiq"
    />
  </div>
</template>

<script>
import SearchDataTableVue from '../../components/util/SearchDataTable.vue'
import { format_money } from '../../util/utils'
import BtnFiltroVue from '../../components/util/BtnFiltro.vue'
import PeriodoPickerVue from '../../components/util/PeriodoPicker.vue'
import BtnConfirmarVue from '../../components/util/BtnConfirmar.vue'
import ModalNewElimDescVue from '../../components/liquidaciones/ModalNewElimDesc.vue'
import moment from 'moment'
import ModalVerEditarLiqVue from '../../components/liquidaciones/ModalVerEditarLiq.vue'

export default {
  name: 'IndexLiquidaciones',
  data(){
    return {
      moment: moment,
      panel: 0,
      search: '',
      load: false,
      search2: '',
      load2: false,
      tab: null,
      objModalNewElimDesc:{
        dialog: false,
        periodoSelect: '',
        accion: '',
        ultPeriodo: '',
      },
      objEditarLiq:{
        dialog: false,
        detalle: {},
        conceptos: [],
      },
      ultMesPeriodo: new Date().getMonth()+1, //mes actual
      ultAnioPeriodo: new Date().getFullYear(), //año actual
      ultPeriodo: '', //periodo actual
      header_detalles: [
        { text: 'Sucursal', value: 'sucursal' },
        { text: 'Id Reserva', value: 'reserva_id', align: 'end' },
        { text: 'Nro. Contrato', value: 'contrato', align: 'end' },
        { text: 'Fecha Inicio', value: 'fecha_desde' },
        { text: 'Fecha Fin', value: 'fecha_hasta' },
        { text: 'Imp. Total', value: 'importe_total', align: 'end',  formatter:format_money  },
        { text: 'Imp. Válido', value: 'importe_valido', align: 'end', formatter:format_money },
        { text: 'Concepto', value: 'concepto_nombre' },
        { text: 'Imp. Comisión', value: 'importe_comision', align: 'end',  formatter:format_money },
        { text: 'Acciones', value: 'acciones', align: 'center', sorteable: false, filtreable: false,},
      ],
      header_resumen: [
        { text: 'Sucursal', value: 'sucursal' },
        { text: 'Concepto', value: 'concepto_nombre' },
        { text: 'Importe', value: 'importe_comision', align: 'end',  formatter:format_money },
        /*{ text: 'Acciones', value: 'acciones' },*/
      ],
      liquidaciones: [],
      liquidacionesDetalles: [],
      liquidacionesResumen: [],
      vendedores: [],
      conceptos: [],
      origenes: [],
      filtroLiquidaciones: {
        periodo: '',
        concepto_id: null,
        reserva_id: null,
        nro_contrato: null,
        canal_id: null,
        origen: '',
      },
      totalComisiona: 0,
      totalComisionaSeguro: 0,
      totalNoComisiona: 0,
      totalNoComisionaSeguro: 0,
      provincias: [],
      buscado: false,
    }
  },
  created(){
    this.init();
    this.ultPeriodo = 10 <= this.ultMesPeriodo && this.ultMesPeriodo <= 12? this.ultMesPeriodo+'/'+this.ultAnioPeriodo : '0'+this.ultMesPeriodo+'/'+this.ultAnioPeriodo
  },
  methods:{
    async init(){
      this.$store.state.loading = true;
      const res = await this.$store.dispatch('liquidaciones/iniciarFormulario')
      this.$store.state.loading = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
      }
      this.origenes = res.canales;
      this.conceptos = res.conceptos;
    },
    async buscar(){
      if(!this.filtroLiquidaciones.periodo){
        return this.$store.dispatch('show_snackbar', { text: 'Es necesario ingresar un período', color: 'warning' })
      }

      this.liquidacionesDetalles = [];
      this.liquidacionesResumen = []
      this.load = true;
      this.search = '';
      this.$store.state.loading = true;
      
      const res = await this.$store.dispatch('liquidaciones/buscarDetalleLiq', {
        periodo: '01/'+this.filtroLiquidaciones.periodo,
        concepto_id: this.filtroLiquidaciones.concepto_id ? this.filtroLiquidaciones.concepto_id : 0,
        reserva_id: this.filtroLiquidaciones.reserva_id ? this.filtroLiquidaciones.reserva_id : 0,
        nro_contrato: this.filtroLiquidaciones.nro_contrato ? this.filtroLiquidaciones.nro_contrato : 0,
        origen: '',
      })
      this.$store.state.loading = false;
      this.load = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', })
      }
      this.buscado = true; // bandera para el boton de eliminar y el botón descargar
      this.liquidacionesDetalles = res.detalles;
      if(this.liquidacionesDetalles.length > 0){
        this.cargarResumen();
      }//else this.buscado = false;
    },
    limpiarFiltros(){
      this.liquidacionesDetalles = [];
      this.liquidacionesResumen = []
      this.search = '';
      this.search2 = '';
      this.buscado = false;
      this.filtroLiquidaciones = {
        periodo: '',
        concepto_id: null,
        reserva_id: null,
        nro_contrato: null,
        canal_id: null,
        origen: '',
      }
    },
    cargarResumen(){
      this.provincias.push(this.liquidacionesDetalles[0].sucursal)
      for(let i in this.liquidacionesDetalles){
        if(this.provincias.indexOf(this.liquidacionesDetalles[i].sucursal) == -1){
          this.provincias.push(this.liquidacionesDetalles[i].sucursal)
        }
      }
      
      for(let j in this.provincias){
        this.totalComisiona = 0;
        this.totalComisionaSeguro = 0;
        this.totalNoComisiona = 0;
        this.totalNoComisionaSeguro = 0;

        for(let i in this.liquidacionesDetalles){
          if(this.liquidacionesDetalles[i].sucursal == this.provincias[j]){
            switch(this.liquidacionesDetalles[i].concepto_id){
              case 1:
                this.totalComisionaSeguro += this.liquidacionesDetalles[i].importe_comision;
                break
              case 2:
                this.totalNoComisionaSeguro += this.liquidacionesDetalles[i].importe_comision
                break
              case 3:
                this.totalComisiona += this.liquidacionesDetalles[i].importe_comision
                break
              case 4:
                this.totalNoComisiona += this.liquidacionesDetalles[i].importe_comision
                break
            }
          }
        }

        this.liquidacionesResumen.push({ sucursal: this.provincias[j], concepto_nombre: 'COMISIONA SEGURO', importe_comision: this.totalComisionaSeguro})
        this.liquidacionesResumen.push({ sucursal: this.provincias[j], concepto_nombre: 'NO COMISIONA SEGURO', importe_comision: this.totalNoComisionaSeguro})
        this.liquidacionesResumen.push({ sucursal: this.provincias[j], concepto_nombre: 'COMISIONA', importe_comision: this.totalComisiona})
        this.liquidacionesResumen.push({ sucursal: this.provincias[j], concepto_nombre: 'NO COMISIONA', importe_comision: this.totalNoComisiona})
      }
    },
    abrirNuevoPeriodo(){
      this.objModalNewElimDesc.accion = ''; 
      this.objModalNewElimDesc.dialog = true;
      this.objModalNewElimDesc.periodoSelect = '';
      this.objModalNewElimDesc.ultPeriodo = '';
    },
    async descargar(){
      this.objModalNewElimDesc.dialog = true;
      this.objModalNewElimDesc.accion = 'descargar';
      this.objModalNewElimDesc.periodoSelect = this.filtroLiquidaciones.periodo;
      this.objModalNewElimDesc.ultPeriodo = this.ultPeriodo;
    },
    //elimina un periodo de liquidación
    async eliminarLiq(){
      this.objModalNewElimDesc.dialog = true;
      this.objModalNewElimDesc.accion = 'eliminar';
      this.objModalNewElimDesc.periodoSelect = this.filtroLiquidaciones.periodo;
      let fecha = new Date(); 
      fecha.setMonth(fecha.getMonth() - 1); 
      this.objModalNewElimDesc.ultPeriodo = 10<=(fecha.getMonth()+1) && (fecha.getMonth()+1)<=12 ? (fecha.getMonth()+1)+'/'+fecha.getFullYear() : '0'+(fecha.getMonth()+1)+'/'+fecha.getFullYear();
    },
    buscarPeriodo(per){
      this.filtroLiquidaciones = {
        periodo: per,
        concepto_id: null,
        reserva_id: null,
        nro_contrato: null,
        canal_id: null,
        origen: '',
      };
      this.buscar();
    },
    //prepara y abre el modal
    verEditarDet(item){
      this.objEditarLiq.detalle = item;
      this.objEditarLiq.dialog = true;
      this.objEditarLiq.conceptos = this.conceptos;
    },
    //Actualiza el array de Detalles
    async actualizarDetalleLiq(nuevo){
      //return 
      this.$store.state.loading = true;
      const res = await this.$store.dispatch('liquidaciones/modificarLiquidacion', { 
        detalle_id: nuevo.id,//numérico. Obligatorio. Indica el Id del detalle a modificar.
        comentario: nuevo.comentario,//string. Optativo.
        concepto_id: nuevo.concepto_id,//numérico. Obligatorio.
        importe_total: nuevo.importe_total,//numérico. Obligatorio.
        importe_valido: nuevo.importe_valido,//numérico. Obligatorio.
        importe_comision: nuevo.importe_comision,//numérico. Obligatorio.
      })
      this.$store.state.loading = false;

      if(res.resultado == 0){ return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', }); }
      
      this.$swal.fire({
        icon: 'success',
        title: 'Se modificó el detalle con éxito',
        text: '',
        timer: 3000
      });

      let pos = this.liquidacionesDetalles.map(e => e.id).indexOf(nuevo.id);
      Object.assign(this.liquidacionesDetalles[pos], nuevo)
      if(this.liquidacionesDetalles.length > 0){
        this.provincias = [];
        this.liquidacionesResumen = [];
        this.cargarResumen();
      }
      this.objEditarLiq.dialog = false;
    },
    async eliminarDetalle(item){
      const resp = await this.$swal.fire({
        icon: 'warning',
        title: `Está seguro eliminar este detalle. ¿Confirma esta accion?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      });
      if(resp.isConfirmed){
        this.$store.state.loading = true;
        const res = await this.$store.dispatch('liquidaciones/eliminarUnDetalleLiq', { detalle_id: item.id })
        this.$store.state.loading = false;

        if(res.resultado == 0){ return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', }); }
        
        this.$swal.fire({
          icon: 'success',
          title: 'Se eliminó el detalle con éxito',
          text: '',
          timer: 3000
        });

        let pos = this.liquidacionesDetalles.indexOf(item);
        this.liquidacionesDetalles.splice(pos,1);
        if(this.liquidacionesDetalles.length > 0){
          this.provincias = [];
          this.liquidacionesResumen = [];
          this.cargarResumen();
        }
      }
    },
    verReserva(item){
      let path = `/reserva/${item.reserva_id}`
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    }
  },
  components:{
    SearchDataTableVue, BtnFiltroVue, PeriodoPickerVue, ModalNewElimDescVue, BtnConfirmarVue, ModalVerEditarLiqVue
  },
  watch:{
  }
}
</script>

<style>

</style>