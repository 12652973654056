<template>
<div>
  <v-dialog
    v-model="activo"
    width="800"
    :persistent="activo"
    scrollable
  >
    <v-card>
      <v-card-title class="">
        <div style="font-size: 20px">Detalle de liquidación {{ detalleCopia.id }}</div>
        <v-spacer></v-spacer>
        <v-btn right icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-3">
        <v-form @submit.prevent="guardarCambios()">
          <v-row class="pt-2">
            <v-col cols="12" sm="6" md="3" class="pt-1">
              N° Reserva
              <v-text-field
                v-model="detalleCopia.reserva_id"
                type="number"
                tabindex="1"
                hide-details
                readonly
                filled
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pt-1">
              Sucursal
              <v-text-field
                v-model="detalleCopia.sucursal"
                tabindex="1"
                readonly
                filled
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pt-1">
              Concepto
              <v-autocomplete
                v-model="detalleCopia.concepto_id"
                item-text="concepto_nombre"
                item-value="concepto_id"
                tabindex="1"
                :items="conceptos"
                hide-details
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pt-1">
              Comentario
              <v-text-field
                v-model="detalleCopia.comentario"
                tabindex="1"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="3" class="pt-1">
              Importe Total
              <TextFieldMoneyVue
                v-model="detalleCopia.importe_total"
                tabindex="1"
                outlined
                dense
                v-bind:properties="{
                  prefix: '$',
                  'hide-details': true,
                  'readonly': true,
                  'filled': true,
                  'validate-on-blur': true
                }"
              />
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pt-1">
              Importe Válido
              <TextFieldMoneyVue
                v-model="detalleCopia.importe_valido"
                tabindex="1"
                outlined
                dense
                v-bind:properties="{
                  prefix: '$',
                  'hide-details': true,
                  'readonly': true,
                  'filled': true,
                  'validate-on-blur': true
                }"
              />
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pt-1">
              Porcentaje
              <v-text-field
                v-model="porcentaje"
                tabindex="1"
                type="number"
                suffix="%"
                hide-details
                outlined
                dense
                @change="calcularImporte()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pt-1">
              Importe Comisión
              <TextFieldMoneyVue
                v-model="detalleCopia.importe_comision"
                tabindex="1"
                outlined
                dense
                v-bind:properties="{
                  prefix: '$',
                  'hide-details': true,
                  'readonly': true,
                  'filled': true,
                  'validate-on-blur': true
                }"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- BOTONES -->
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          color="secondary"
          @click="activo = false"
        >cancelar</v-btn>
        <BtnConfirmarVue
          :texto="`Aplicar cambios al detalle de liquidación ${detalleCopia.id}. ¿Confirma esta accion?`"
          :nombre="'GUARDAR CAMBIOS'"
          :loading="load"
          @action="controlarCampos()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import { roundNumber } from '../../util/utils';
import BtnConfirmarVue from '../util/BtnConfirmar.vue';
import TextFieldMoneyVue from '../util/TextFieldMoney.vue';

export default {
  name: 'ModalVerEditarLiq',
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default: {}
    }
  },
  created(){
  },
  data(){
    return{
      load: false,
      porcentaje: 0,
      detalleCopia: {},
    }
  },
  computed:{
    activo:{
      get(){
        return this.datos.dialog;
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    detalle:{
      get(){
        return this.datos.detalle;
      },
    },
    conceptos:{
      get(){
        return this.datos.conceptos;
      },
    },
  },
  methods:{
    async controlarCampos(){
      if(!this.detalleCopia.concepto_id){
        return this.$store.dispatch('show_snackbar', { text: 'Es necesario ingresar un concepto', color: 'warning' })
      }
      this.$emit('actualizarDetalleLiq', this.detalleCopia)
    },
    calcularImporte(){
      if(!this.porcentaje){
        this.porcentaje = 0;
        return 
      }
      this.detalleCopia.importe_comision = this.detalleCopia.importe_valido * (this.porcentaje /100)
    }
  },
  components:{
    BtnConfirmarVue, TextFieldMoneyVue
  },
  watch:{
    activo: function(val){
      if(val){
        this.detalleCopia = JSON.parse(JSON.stringify(this.detalle));
        this.porcentaje = roundNumber((this.detalleCopia.importe_comision/this.detalleCopia.importe_valido)*100,2)
      }else{
        //this.detalleCopia = {}
      }
    },
    porcentaje: function(val){      
      if(val < 0){
        this.porcentaje = Math.abs(this.porcentaje);
      }
    },
    'detalleCopia.concepto_id': {
      handler: function(val){
        this.detalleCopia.concepto_nombre = (this.conceptos.filter(e => e.concepto_id == val))[0].concepto_nombre
      },
      deep: true,
    }
  }
}
</script>

<style>

</style>